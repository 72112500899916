import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useQueryString from "../../../utils/hooks/useQueryString";
import Pagination from "../../component/Pagination";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { useQuery } from 'react-query';
import { fetchSalesList } from "./saleApi";
import moment from "moment";
const queryString = require('query-string');

const SalesList = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    const qsData = useQueryString();
    const [searchKeyword, setSearchKeyword] = useState('');
    // const [searchService, setSearchService] = useState('');
    const [searchStdate, setSearchStdate] = useState(null);
    const [searchEddate, setSearchEddate] = useState(null);

    const {data:salesList, isSuccess, isLoading, error, isError } = useQuery({
        queryKey:['salesList', qsData], 
        queryFn:fetchSalesList
    });

    
    useEffect(() => {
        setSearchStdate( ( qsData.stdate && new Date(qsData.stdate) ) || null );
        setSearchEddate( ( qsData.eddate && new Date(qsData.eddate) ) || null );
        setSearchKeyword(qsData.keyword || '');
    }, [qsData.eddate, qsData.keyword, qsData.stdate]);


    
    const fetchList = useCallback((page, option={})=>{
        let tempSearchOption = {page, 
            keyword:searchKeyword, 
            stdate:searchEddate && moment(searchStdate).format('YYYY-MM-DD'),
            eddate:searchEddate && moment(searchEddate).format('YYYY-MM-DD'),
            ...option};
        
        navigate({ pathname: '/sales', search: "?" + queryString.stringify(tempSearchOption) });
    }, [navigate, searchEddate, searchKeyword, searchStdate]);

    const searchKeyDown = useCallback((e)=>{
        if(e.key==='Enter') fetchList(0);
    }, [fetchList]);

    if( isLoading ) return null;
    if( isError ) return error;
    
    return (
        <div className="sale_list m-2">
            <div className="controls">
                <div className="row ">
                    <div className="col-auto">
                        <DatePicker locale={ko} selected={searchStdate} onChange={(date) => setSearchStdate(date)} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="시작" />
                    </div>
                    <div className="col-auto">
                        <DatePicker locale={ko} selected={searchEddate} onChange={(date) => setSearchEddate(date)} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="종료" />
                    </div>
                    <div className="col-auto">
                        <input type="text" className="form-control" placeholder="검색" tabIndex="0" id="keyword"
                        value={searchKeyword}
                        onKeyDown={searchKeyDown}
                             onChange={e=>setSearchKeyword(e.target.value)} />
                    </div>
                    <div className="col-auto">
                        <button type="submit" className="btn btn-outline-primary mb-3" onClick={()=>fetchList(0)}>검색</button>
                    </div>
                </div>
                <Link className="btn btn-primary float-end" to="/sales/create">계약추가</Link>
            </div>
            
            <table className="table table-striped table-bordered table-hover table_sale">
                <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width="70" />
                    <col />
                    <col />
                    <col width="70" />
                    <col />
                    <col width="72" />
                    <col width="72" />
                    <col width="40" />
                    <col width="40" />
                    <col width="40" />
                    <col />
                </colgroup>

                <thead>
                    <tr>
                        <th>#</th>
                        <th>계약명</th>
                        <th>품목(규격)</th>
                        <th>발주처</th>
                        <th>납품처</th>
                        <th>계약일</th>
                        <th>정가</th>
                        <th>계약금액</th>
                        <th>발주일</th>
                        <th>발주금액</th>
                        <th>납기일</th>
                        <th>납품일</th>
                        <th>매입</th>
                        <th>매출</th>
                        <th>완료</th>
                        <th>비고</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        salesList.data?.list.map((sale)=>{
                            return (
                                <tr className={`sale ${ (sale.buyTaxbillsCount===0 || sale.saleTaxbillsCount===0 )?'table-success':'' } ${ ( (sale.buyTaxbillsCount-sale.buyTaxbillsPaidCount)!==0 || (sale.saleTaxbillsCount-sale.saleTaxbillsPaidCount)!==0 )?'unpaind':'' } ${ null===sale.contractDate?'table-warning':'' } ${ null===sale.completeDate?'table-info':'' } `} key={sale.id}>
                                    <td>{ sale.id }</td>
                                    <td>
                                        <Link to={`/sales/view/${sale.id}${location.search}`}>
                                            { sale.title }
                                        </Link>
                                    </td>
                                    <td>{ sale.service }</td>
                                    <td>{ sale.client }</td>
                                    <td>{ sale.buyer }</td>

                                    <td className="date">{ sale.contractDate===null?null:sale.contractDate.replace(/-+/gi, '.') }</td>
                                    <td className="price">{ sale.price?.toLocaleString() }</td>
                                    <td className="price">{ sale.salePrice?.toLocaleString() }</td>
                                    <td className="date">{ sale.orderDate===null?null:sale.orderDate.replace(/-+/gi, '.') }</td>
                                    <td className="price">{ sale.orderPrice?.toLocaleString() }</td>

                                    <td className="date">{ sale.dueDate===null?null:sale.dueDate.replace(/-+/gi, '.') }</td>
                                    <td className="date">{ sale.completeDate===null?null:sale.completeDate.replace(/-+/gi, '.') }</td>
                                    <td className="BUY_taxbill">
                                        <strong>{ sale.buyTaxbillsCount }</strong> - <strong>{ sale.buyTaxbillsPaidCount }</strong>
                                    </td>
                                    <td className="SALE_taxbill">
                                        <strong>{ sale.saleTaxbillsCount }</strong> - <strong>{ sale.saleTaxbillsPaidCount }</strong>
                                    </td>
                                    <td className="complete">
                                        {sale.isComplete==='Y'?'완료':''}
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            { isSuccess && <Pagination page={salesList.data?.page} totalPage={salesList.data?.totalPages} gotoPage={fetchList} /> }
            
        </div>
    )
}

export default SalesList;